import { Injectable, OnDestroy, OnInit, ViewContainerRef } from "@angular/core";
import { ActivatedRoute, Data, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { lastValueFrom, map, Observable, ReplaySubject, Subscription } from "rxjs";

import { ISortResponse, ITableData, ITableHead } from "../_components";
import {
  BreadCrumbsService,
  ContentFiltration, IGetListOption,
  IGetListOptionFilter, ISettings,
  ISettingsSection,
  SettingsRestApiService
} from "../_services";
import { LocalizedDatePipe } from "../_pipes";
import { Filtration, FiltrationValues } from './section-with-table.types';
import { IColumnWidth } from "../_directives";


@Injectable({
  providedIn: 'root',
})
export class SectionWithTable implements OnInit, OnDestroy {
  protected settingsSubscription?: Subscription = undefined;
  protected sectionKey: string; // Ключ раздела, под ним загружаются и сохраняются настройки интерфейса.
  protected settings: ISettingsSection; // Текущие настройки раздела и отображения таблицы.
  protected tableData$: ReplaySubject<ITableData[][]>; // Канал отправки данных в таблицу.
  protected tableBusy$: ReplaySubject<boolean>; // Канал отправки состояния "занято" в таблицу.
  protected buttonBusy$: ReplaySubject<boolean>; // Канал отправки состояния "занято" в кнопку.
  protected pageCounts$: number[]; // Размеры страниц таблицы.
  protected contentFiltrationModel$: ContentFiltration[];
  protected tableHeader: ITableHead[] = []; // Заголовок таблицы.
  protected dataID: number[] = []; // Все идентификаторы данных с учётом текущей фильтрации данных.
  protected filtration: Filtration; // Фильтрация данных таблицы по колонкам.
  protected filtrationExt: any; // Расширенная фильтрация, например на основе предопределений.

  /**
   * Конструктор.
   * @param viewContainerRef   - Ссылка вью HTML элемента.
   * @param settingsService    - Сервис настроек.
   * @param route              - Роутинг.
   * @param router             - Сервис роутинга.
   * @param titleService       - Сервис изменения заголовка HTML документа.
   * @param breadCrumbsService - Сервис установки хлебных крошек в компонент хлебных крошек.
   * @param localizedDatePipe  - Локализация представления даты и времени.
   */
  constructor(
    protected viewContainerRef: ViewContainerRef,
    protected settingsService: SettingsRestApiService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected titleService: Title,
    protected breadCrumbsService: BreadCrumbsService,
    protected localizedDatePipe: LocalizedDatePipe,
  ) {
    this.settingsSubscription = undefined;
    this.sectionKey = '';
    this.pageCounts$ = [5, 10, 15, 25, 50, 100, 200, 500];
    this.settings = {pageCount: this.pageCounts$[2], pageCurrent: 1, sortColumn: 'id', sortBy: 'asc'};
    this.tableData$ = new ReplaySubject<ITableData[][]>(1);
    this.tableBusy$ = new ReplaySubject<boolean>(1);
    this.buttonBusy$ = new ReplaySubject<boolean>(1);
    this.contentFiltrationModel$ = [];
    this.filtration = {isShow: false, values: {}, focus: {}};
    this.filtrationExt = '';
  }

  /** Инициализатор. */
  ngOnInit(): void {
    // Загрузка настроек раздела, настройки загружаются при переходе в раздел.
    const settings$: Observable<ISettingsSection> = this.route.data.pipe<ISettingsSection>(
      map((data: Data): any => data['settings'])
    );
    this.settingsSubscription = settings$.subscribe((settings: ISettingsSection): void => {
      if (settings.pageCount != 0 && settings.sortColumn !== '') {
        this.settings = settings;
        if (this.settings.presetFiltrationConst !== undefined) {
          this.filtrationExt = this.settings.presetFiltrationConst;
        }
      }
      if (this.settings.filtration) {
        this.settings.filtration.forEach((value: IGetListOptionFilter): void => {
          if (value.value === '') return;
          this.filtration.values[value.name] = this.filtrationClean(value.value);
        });
      }
      // Создание заголовка таблицы.
      this.makeTableHeader([]);
      // После загрузки настроек, вне зависимости от результата, инициируется обновление/загрузка данных.
      this.dataUpdate();
    });
    this.applyFiltration();
  }

  /** Деструктор. */
  ngOnDestroy(): void {
    this.breadCrumbsService.clean(); // Очистка хлебных крошек при выходе.
    if (this.settingsSubscription != undefined) {
      this.settingsSubscription.unsubscribe();
      this.settingsSubscription = undefined;
    }
  }

  /** Очистка фильтра от служебных символов. */
  protected filtrationClean(s: string): string {
    if (s === undefined || s === '') return s;
    s = s.replace(/^\*(.*)/g, '$1');
    s = s.replace(/(.*)\*$/g, '$1');
    return s;
  }

  /** Создание заголовка таблицы. */
  protected makeTableHeader(header: ITableHead[]): void {
    this.tableHeader = header;
    // Применение настроек сортировки.
    for (let n: number = 0; n < this.tableHeader.length; n++) {
      if (this.tableHeader[n].by === this.settings.sortColumn) {
        this.tableHeader[n].sort = this.settings.sortBy;
      }
    }
    // Применение настроек ширины колонок.
    try {
      const map: any = JSON.parse(this.settings.columnWidth || '{}');
      for (let n: number = 0; n < this.tableHeader.length; n++) {
        if (map[n.toString()] && map[n.toString()] > 0) {
          this.tableHeader[n].width = map[n.toString()];
        }
      }
    } catch (e: unknown) {
      // console.error('парсинг json прерван ошибкой:', e);
    }
  }

  /**
   * Загрузка данных с сервера.
   * Эту функцию необходимо переопределить в конечном классе, так как в базовом классе функция пустая.
   */
  protected dataUpdate(): void {
    this.dataID = [];
  }

  /**
   * Применение отображения настроек фильтрации, а так же заголовка страницы и хлебных крошек.
   * Эту функцию необходимо переопределить в конечном классе, так как в базовом классе функция пустая.
   */
  protected applyFiltration(): void {
  }

  /** Создание опций с настройками лимита, сортировки и фильтрации данных. */
  protected opt(filter: IGetListOptionFilter[] = []): IGetListOption {
    let ret: IGetListOption;
    let rex: RegExp;

    // Добавление фильтрации по полям.
    if (this.settings.filtration) {
      rex = new RegExp('[*?]+');
      this.settings.filtration.forEach((value: IGetListOptionFilter): void => {
        if (value.value !== undefined) value.value = value.value.replace(/;/g, '');
        if (value.value === '') return;
        if (!rex.test(value.value) && (value.type === 'ke' || value.type === 'kn')) {
          value.value = '*' + value.value + '*';
        }
        filter.push({
          type: value.type,
          name: value.name,
          value: value.value,
        });
      });
    }
    ret = {by: [{name: this.settings.sortColumn, type: this.settings.sortBy}], filter: filter};

    return ret;
  }

  /** Сохранение настроек. */
  protected settingsSave(): void {
    let map: Map<string, number | undefined>;
    let data: ISettings = {};

    // Ключ раздела не установлен, сервер не примет данные с пустым ключом.
    if (this.sectionKey === '') return;
    // Настройки ширины колонок таблицы.
    map = new Map<string, number>();
    for (let n: number = 0; n < this.tableHeader.length; n++) {
      map.set(n.toString(), this.tableHeader[n].width);
    }
    this.settings.columnWidth = this.mapToJson(map);
    try {
      data[this.sectionKey] = JSON.stringify(this.settings);
    } catch (e: unknown) {
      console.error('не удалось сохранить настройки интерфейса.');
      return
    }
    lastValueFrom(this.settingsService.save(data)).then((): void => {
      // console.log('настройки успешно сохранены.');
    });
  }

  /** Сериализация объекта типа Map() в JSON */
  protected mapToJson(map: Map<string, number | undefined>): string {
    return JSON.stringify(
      Array.from(map.entries())
        .reduce((o: Record<string, any> = {}, [key, value]: [string, number | undefined]): Record<string, any> => {
          o[key] = value;
          return o;
        }, {})
    )
  }


  /**
   * Загрузка по идентификаторам информации для таблицы и отображение данных в таблице.
   * Запрашиваются данные только для идентификаторов помещающихся на текущей странице таблицы.
   * Эту функцию необходимо переопределить в конечном классе, так как в базовом классе функция пустая.
   */
  protected pageUpdate(): number[] {
    let ids: number[];
    let begin: number;
    let end: number | undefined;

    // Получаем кусок данных идентификаторов текущей страницы (array.slice( begin [,end] )).
    begin = (this.settings.pageCurrent - 1) * this.settings.pageCount;
    end = this.settings.pageCurrent * this.settings.pageCount;
    if (end > this.tableDataCount) end = undefined;
    ids = this.dataID.slice(begin, end);
    // Если нечего отображать и находимся на не последней странице, переход на предыдущую страницу.
    if (ids.length === 0 && this.settings.pageCurrent > 1) {
      setTimeout((): void => {
        this.settings.pageCurrent -= 1;
        this.pageUpdate();
      }, 200);
      return [];
    }

    return ids;
  }

  /** ******************************************************************************************************************
   * Добытчики и установщики приватных значений класса, для использования из шаблона.
   */

  /** Возвращает массив заголовков колонок таблицы. */
  protected get tableHead(): ITableHead[] {
    return this.tableHeader
  }

  /** Возвращает объект подписки на данные таблицы. */
  protected get tableDataSubject(): ReplaySubject<ITableData[][]> {
    return this.tableData$
  }

  /** Возвращает объект подписки на состояние "занято" таблицы. */
  protected get tableBusySubject(): ReplaySubject<boolean> {
    return this.tableBusy$;
  }

  /** Возвращает объект подписки на состояние "занято" кнопки. */
  protected get buttonBusySubject(): ReplaySubject<boolean> {
    return this.buttonBusy$;
  }

  /** Возвращает общее количество данных. */
  protected get tableDataCount(): number {
    return this.dataID.length;
  }

  /** Возвращает количество страниц которые должен отобразить пагинатор. */
  protected get tableDataPages(): number {
    let ret: number;

    ret = this.tableDataCount / this.settings.pageCount;
    ret = Math.trunc(ret);
    if (this.tableDataCount % this.settings.pageCount !== 0) ret++;

    return ret;
  }

  /** Возвращает массив возможных размеров страниц таблицы. */
  protected get pageCounts(): number[] {
    return this.pageCounts$;
  }

  /** Возвращает выбранный размер страницы таблицы. */
  protected get pageCount(): number {
    return this.settings.pageCount;
  }

  /** Возвращает номер текущей страницы. */
  protected get page(): number {
    return this.settings.pageCurrent;
  }

  /**
   * /Добытчики и установщики приватных значений класса, для использования из шаблона.
   * *******************************************************************************************************************
   */

  /** ******************************************************************************************************************
   * Настройки фильтрации данных в таблице.
   */

  /** Возвращается "истина", если существует хотя бы одна настройка фильтрации. */
  protected get isFilterActive(): boolean {
    let ret: boolean = this.filtration.isShow;
    if (this.settings.filtration) {
      this.settings.filtration.forEach((value: IGetListOptionFilter): void => {
        if (value.value !== '' && value.value !== '*') ret = true;
      });
    }
    return ret;
  }

  /** Возвращаются настройки фильтрации сущности. */
  protected get contentFiltrationModel(): ContentFiltration[] {
    return this.contentFiltrationModel$;
  }

  /** Выгрузка значения фильтра. */
  protected get contentFiltrationValues(): FiltrationValues {
    return this.filtration.values;
  }

  /** Получение события клика по кнопке отображения блока настроек фильтрации. */
  protected onFilterClick(): void {
    this.filtration.isShow = true;
  }

  /** Получение события клика по кнопке сброса фильтров. */
  protected onFilterClear(): void {
    this.settings.filtration = undefined;
    this.filtration.isShow = false;
    this.filtration.values = {};
    // Обновление данных и сохранение настроек.
    this.settingsSave();
    this.dataUpdate();
  }

  /** Возвращает значение состояния фокусировки поля элементов фильтрации. */
  protected contentFiltrationIsFocus(fieldId: string): boolean {
    let ret: boolean = false;

    if (this.contentFiltrationValues[fieldId] && this.contentFiltrationValues[fieldId] !== '') ret = true;
    if (this.filtration.focus && this.filtration.focus[fieldId]) ret = true;

    return ret;
  }

  /** Изменение значения состояния фокусировки поля элементов фильтрации. */
  protected contentFiltrationFocusChange(fieldId: string, isFocus: boolean): void {
    if (this.filtration.focus === undefined) return;
    this.filtration.focus[fieldId] = isFocus;
  }

  /** Событие изменения значения фильтра. */
  protected contentFiltrationChange(fieldId: string): void {
    let found: boolean;

    this.contentFiltrationModel$.forEach((filter: ContentFiltration): void => {
      if (filter.field !== fieldId) return;
      found = false;
      if (this.settings.filtration) {
        this.settings.filtration.forEach((value: IGetListOptionFilter, n: number): void => {
          if (value.name === fieldId) {
            this.settings.filtration![n].type = filter.type;
            this.settings.filtration![n].value = this.filtration.values[fieldId];
            found = true;
          }
        });
      }
      if (!found) {
        if (!this.settings.filtration) this.settings.filtration = [];
        this.settings.filtration.push({
          type: filter.type,
          name: fieldId,
          value: this.filtration.values[fieldId],
        })
      }
    });
    // Сброс текущей страницы пагинации.
    this.settings.pageCurrent = 1;
    // Обновление данных и сохранение настроек.
    this.settingsSave();
    this.dataUpdate();
  }

  /** Событие сброса значения фильтра. */
  protected contentFiltrationClear(fieldId: string): void {
    this.filtration.values[fieldId] = '';
    this.contentFiltrationChange(fieldId);
  }

  /**
   * /Настройки фильтрации данных в таблице.
   * *******************************************************************************************************************
   */

  /** Получение события изменения сортировки таблицы. */
  protected onSortResponse(rsp: ISortResponse): void {
    this.settings.sortColumn = rsp.orderName;
    this.settings.pageCurrent = 1;
    if (rsp.sort && (rsp.sort === 'asc' || rsp.sort === 'desc')) {
      this.settings.sortBy = rsp.sort as 'asc' | 'desc' | 'none';
    } else this.settings.sortBy = 'asc';
    this.settingsSave();
    this.dataUpdate();
  }

  /** Получение события изменения номера текущей страницы */
  protected onPageResponse(rsp: number): void {
    this.settings.pageCurrent = rsp;
    // Обновление данных в таблице.
    this.settingsSave();
    this.pageUpdate();
  }

  /** Получение события изменения размера колонки. */
  protected onColumnWidthResponse(cwr: Map<number, IColumnWidth>): void {
    this.tableHeader.forEach((th: ITableHead, index: number) => {
      if (cwr.has(index) && cwr.get(index) !== undefined) {
        const cw: IColumnWidth | undefined = cwr.get(index);
        if (cw && !cw.isNoResize) th.width = cw.width;
      }
    });
    this.settingsSave();
  }

  /** Получение события изменения размера количества элементов отображаемых на странице. */
  protected onPageCountSelected(n: number): void {
    this.settings.pageCount = n;
    this.settings.pageCurrent = 1;
    this.settingsSave();
    this.pageUpdate();
  }
}
