import { AfterViewChecked, AfterViewInit, ElementRef, EventEmitter, Injectable, Input } from "@angular/core";
import { OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { ReplaySubject, startWith, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

import { IDropboxItem, IDropboxFiltration } from "./dropbox-with-filter.types";

/**
 * Родительский класс для реализации выпадающего списка на основе данных справочника загружаемого с сервера.
 *
 * @param selectId       - Идентификатор выбранного элемента выпадающего списка.
 * @param filter         - Массив фильтров для получения данных справочника. IDropboxFiltration[].
 * @param selectIdChange - Канал, в который поступит выбранное значение выпадающего списка.
 * @param outViewDone    - Канал события, в который поступит событие окончания отображения выпадающего списка.
 */
@Injectable({
  providedIn: 'root',
})
export class DropboxWithFilter implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  protected onViewDoneSubscribe?: Subscription; // Подписка на канал события окончания отображения компоненты.
  protected filterSubscribe?: Subscription; // Подписка на канал изменения фильтрации.
  protected isBusy$: boolean; // Состояние занято.
  protected isShow$: boolean; // Состояние открыт или закрыт выпадающий список.
  protected isFocus$: boolean; // Состояние фокусировки на элементе.
  protected items$: IDropboxItem[]; // Элементы выпадающего списка.
  protected isMouseLeaveButton: boolean; // Состояние - мышка вышла за пределы кнопки.
  protected isMouseLeaveDropDown: boolean; // Состояние - мышка вышла за пределы выпадающего списка.
  protected isMouseLeaveFilter: boolean; // Состояние - мышка вышла за пределы фильтра.
  protected scrollToSelected$: boolean; // Скролить выпадающий список до выбранного элемента.
  protected filter: IDropboxFiltration[]; // Полученные настройки фильтрации запроса данных с сервера.

  protected filterByName: string; // Фильтр поиска записи в выпадающем списке.
  protected isLockByFilter: boolean; // Статус блокировки по фильтру.
  protected isLockCause: string; // Описание причины блокировки.

  protected doHide: boolean; // Флаг принудительного скрытия выпадающего списка.

  /**
   * Входящие и результирующие переменные.
   * Все переменные @Input и @Output необходимо переопределить в конечном классе!
   */
  @Input('placeholder') protected placeholder: string;
  @Input('selectId') protected selectId: number;
  @Input('isInvalid') protected isInvalid: boolean;
  @Input('isTouched') protected isTouched: boolean;
  @Input('isReadonly') protected isReadonly: boolean;
  @Input('filter') protected filter$?: ReplaySubject<IDropboxFiltration[]>;
  @Output('selectIdChange') protected readonly selectIdChange: EventEmitter<number>;
  @Output('outViewDone') protected readonly outViewDone: EventEmitter<void>;

  /**
   * Получение события окончания выполнения цикла ngFor.
   * Все переменные @View*** необходимо переопределить в конечном классе!
   */
  @ViewChild('wrapperWithScroll', {read: ElementRef}) protected wrapperWithScroll$!: ElementRef<HTMLInputElement>;
  @ViewChildren('displayExpectationsData') protected displayExpectationsData$!: QueryList<ElementRef>;
  @ViewChildren('displayExpectationsEmpty') protected displayExpectationsEmpty$!: QueryList<ElementRef>;


  /**
   * Конструктор.
   */
  constructor() {
    [this.onViewDoneSubscribe, this.filterSubscribe] = [undefined, undefined];
    [this.isBusy$, this.isShow$, this.isFocus$, this.items$] = [true, false, false, []];
    [this.isMouseLeaveButton, this.isMouseLeaveDropDown, this.isMouseLeaveFilter] = [false, false, false];
    [this.scrollToSelected$, this.filter] = [false, []];
    [this.filterByName, this.isLockByFilter, this.isLockCause, this.placeholder] = ['', false, '', '-'];
    [this.selectId, this.selectIdChange, this.outViewDone] = [-1, new EventEmitter<number>(), new EventEmitter<void>()];
    this.onViewDoneSubscribe = this.outViewDone.subscribe((_: void): boolean => this.isBusy$ = false);
    [this.isInvalid, this.isTouched, this.isReadonly] = [false, false, false];
    this.doHide = false;
  }

  /** Инициализатор. */
  ngOnInit(): void {
    [this.isBusy$, this.items$] = [false, []];
    // Подписка на изменение фильтра.
    if (this.filter$ !== undefined) {
      this.filterSubscribe = this.filter$.subscribe((value: IDropboxFiltration[]): void => {
        this.filter = [];
        value.forEach((v: IDropboxFiltration) => this.filter.push(v));
        this.isLockByFilter = false;
        this.isLockCause = '';
        this.updateData();
      });
    }
    // Загрузка данных.
    this.updateData();
  }

  /** Деструктор. */
  ngOnDestroy(): void {
    if (this.onViewDoneSubscribe) {
      this.onViewDoneSubscribe.unsubscribe();
      this.onViewDoneSubscribe = undefined;
    }
    if (this.filterSubscribe) {
      this.filterSubscribe.unsubscribe();
      this.filterSubscribe = undefined;
    }
  }

  /** Событие завершения отображения элементов HTML страницы. */
  ngAfterViewInit(): void {
    if (this.displayExpectationsData$) {
      this.displayExpectationsData$.changes
        .pipe(
          startWith(null),
          debounceTime(0),
        )
        .subscribe(() => this.ngForIsDone());
    }
    if (this.displayExpectationsEmpty$) {
      this.displayExpectationsEmpty$.changes
        .pipe(
          startWith(null),
          debounceTime(0),
        )
        .subscribe(() => this.ngForIsDone());
    }
  }

  /** После проверки вью. Можно менять скроллинг. */
  ngAfterViewChecked(): void {
    if (this.isShow$ && this.scrollToSelected$) {
      this.scrollToSelected$ = false;
      this.scrollTo();
    }
  }

  /** Событие завершения цикла печати строк выпадающего списка. */
  protected ngForIsDone(): void {
    this.outViewDone.emit(void {});
  }

  /** Прокрутка выпадающего списка до выбранного элемента. */
  protected scrollTo(): void {
    const keyDiv: string = 'DIV';
    const className: string = 'thisIsSelectedItem';
    let nodes: NodeListOf<ChildNode>;
    let nodeHeight: number = 0;
    let top: number = 0;

    if (this.wrapperWithScroll$ === undefined) return;
    nodes = this.wrapperWithScroll$.nativeElement.childNodes;
    nodes.forEach((node: ChildNode): void => {
      let div: HTMLDivElement;
      let found: boolean = false;
      if (node.nodeName === keyDiv) {
        div = node as HTMLDivElement;
        div.classList.forEach((value: string, _key: number, _parent: DOMTokenList): void => {
          if (value === className) found = true;
        })
        if (found) {
          nodeHeight = div.offsetHeight;
          top = div.offsetTop - (nodeHeight * 2);
          if (top < 0) top = 1;
        }
      }
    });
    if (top > 2) top -= 2;
    if (top > 0) this.wrapperWithScroll$.nativeElement.scrollTo(0, top);
  }

  /** Загрузка или обновление данных выпадающего списка. */
  protected updateData(): void {
    this.isBusy$ = true;
    this.items$ = [];
  }

  /** Состояние занято. */
  protected get isBusy(): boolean {
    return this.isBusy$;
  }

  /** Состояние открыт или закрыт выпадающий список. */
  protected get isShow(): boolean {
    return this.isShow$;
  }

  /** Состояние открыт или закрыт выпадающий список. */
  protected set isShow(v: boolean) {
    this.isShow$ = v;
  }

  /** Состояние фокусировки на элементе. */
  protected get isFocus(): boolean {
    return this.isFocus$
  }

  /** Значение выбранного элемента формы или заполнитель */
  protected get selectedLabel(): string {
    let ret: string = this.placeholder;

    this.items$.forEach((value: IDropboxItem): void => {
      if (value.id === this.selectId) ret = value.name;
    });

    return ret;
  }

  /** Возвращается "истина", если текущее значение элемента формы является заполнителем. */
  protected get isSelectedLabelPlaceholder(): boolean {
    let ret: boolean = true;

    this.items$.forEach((value: IDropboxItem): void => {
      if (value.id === this.selectId) ret = false;
    });

    return ret;
  }

  /** Идентификатор текущего выбранного элемента справочника. */
  protected get itemID(): number {
    return this.selectId;
  }

  /** Обработка результата после обработки состояния мыши при наведении. */
  protected onMouse(): void {
    if (this.isMouseLeaveButton && this.isMouseLeaveDropDown && this.isMouseLeaveFilter && this.isShow$)
      this.isShow$ = false;
  }

  /** Изменение состояния наведения мышки. */
  protected onMouseEnter(item: 'button' | 'dropdown' | 'filter'): void {
    switch (item) {
      case 'button':
        this.isMouseLeaveButton = false;
        break;
      case 'dropdown':
        this.isMouseLeaveDropDown = false;
        break;
      case 'filter':
        this.isMouseLeaveDropDown = false;
        break;
    }
    this.onMouse();
  }

  /** Изменение состояния наведения мышки. */
  protected onMouseLeave(item: 'button' | 'dropdown' | 'filter'): void {
    this.doHide = false;
    switch (item) {
      case 'button':
        this.isMouseLeaveButton = true;
        break;
      case 'dropdown':
        this.isMouseLeaveDropDown = true;
        break;
      case 'filter':
        this.isMouseLeaveDropDown = true;
        break;
    }
    this.onMouse();
  }

  /** Изменение состояния фокусировки пользователя на элементе. */
  protected async onChangeFocus(v: boolean): Promise<void> {
    if (this.isBusy) return;
    this.isFocus$ = v;
  }

  /** Выгрузка элементов выпадающего списка. */
  protected get items(): IDropboxItem[] {
    return this.items$
  }

  /** Изменилось значение в поле фильтра. */
  protected onChangeFilter(_: KeyboardEvent): void {
    this.updateData();
  }

  /** Изменение состояния открыт/закрыт выпадающего списка. */
  protected async onClick($event: MouseEvent): Promise<void> {
    $event.stopPropagation();
    if (this.isBusy || this.isReadonly) return;
    this.isTouched = true;
    this.scrollToSelected$ = true;
    if (this.isLockByFilter && !this.isShow$) return;
    this.isShow$ = !this.isShow$;
    if (this.isShow)
      [this.isMouseLeaveButton, this.isMouseLeaveDropDown, this.isMouseLeaveFilter] = [false, true, true];
  }

  /** Выбор элемента справочника и отправка события об изменении значения. */
  protected onSelected(id: number): void {
    this.selectId = id;
    this.isTouched = false;
    this.selectIdChange.next(this.selectId);
    this.isShow = false;
    this.doHide = true;
  }
}
