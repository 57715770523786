<!-- Шаблон контента модального окна. -->
<ng-template #modalTemplate>
  <div class="h-full w-full flex flex-col gap-[20px]">
    <!-- Заголовок. -->
    <h2 class="pb-[20px] text-[14px] text-center uppercase">{{title}}</h2>
    <!-- /Заголовок. -->

    <!-- Текст. -->
    <div
      class="text-bbBlack max-w-[794px] flex flex-col">
      <div
        *ngFor="let msg of message"
        class="flex text-[12px] leading-[18px]">{{msg}}</div>
    </div>
    <!-- /Текст. -->

    <!-- Блок отображения ошибки. -->
    <div *ngIf="actionError$.length > 0" class="flex">
      <div class="text-[14px] text-left">
        <div class="min-h-[18px] px-[14px] text-bbRedRegular border-l-[2px] border-l-bbRedRegular">
          <div *ngFor="let err of actionError$">{{err}}</div>
        </div>
      </div>
    </div>
    <!-- /Блок отображения ошибки. -->

    <!-- Кнопки. -->
    <div
      class="flex flex-row w-full gap-[12px]">
      <component-input-button-regular
        [button-type]="'submit'"
        [label]="'Удалить'"
        [activeStyleNormal]="'bg-bbRedButtonNormal'"
        [activeStyleHover]="'bg-bbRedButtonHover text-bbWhite'"
        (onClick)="onYes($event)"
        class="w-auto"></component-input-button-regular>
      <component-input-button-regular
        [button-type]="'submit'"
        [label]="'Отмена'"
        (onClick)="onNot($event)"
        class="w-full"></component-input-button-regular>
    </div>
    <!-- /Кнопки. -->

  </div>
</ng-template>
<!-- /Шаблон контента модального окна. -->

<!-- Компонент обёртки модального окна, в котором отобразится шаблон модального окна. -->
<ui-modal-wrapper #addWrapper>
  <ng-container *ngTemplateOutlet="modalTemplate"></ng-container>
</ui-modal-wrapper>
<!-- /Компонент обёртки модального окна, в котором отобразится шаблон модального окна. -->
