import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DeleteRegularComponent } from './delete-regular.component';
import { InputButtonRegularModule } from "../../_components";
import { ModalWrapperModule } from "../modal-wrapper";


@NgModule({
  declarations: [
    DeleteRegularComponent,
  ],
  imports: [
    CommonModule,
    InputButtonRegularModule,
    ModalWrapperModule,
  ],
  exports: [
    DeleteRegularComponent,
  ],
})
export class DeleteRegularModule { }
