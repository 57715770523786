import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";

import { SpriteIconModule } from "../../_components";
import { DropboxDictionaryComponent } from './dropbox-dictionary.component';


@NgModule({
  declarations: [
    DropboxDictionaryComponent,
  ],
  imports: [
    CommonModule,
    SpriteIconModule,
    FormsModule,
  ],
  exports: [
    DropboxDictionaryComponent,
  ],
})
export class DropboxDictionaryModule { }
