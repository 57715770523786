<section
  [class.open]="display"
  class="fixed z-[65500] inset-0 overflow-y-auto">
  <div class="flex items-center justify-center min-h-screen min-w-[810px] text-center bg-black bg-opacity-60">
    <!-- Центральная область модального окна. -->
    <div class="flex flex-row">

      <!-- Область контента. -->
      <div
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
        (click)="$event.stopPropagation()"
        class="inline-block align-bottom bg-white min-w-[794px] text-left overflow-hidden">
        <div class="bg-white px-[50px] py-[30px]">
          <ng-content></ng-content>
        </div>
      </div>
      <!-- /Область контента. -->

      <!-- Кнопка закрытия модального окна. -->
      <div
        (mouseenter)="onMouseEnter($event)"
        (mouseleave)="onMouseLeave($event)"
        class="relative right-0 ml-[4px]">
        <component-sprite-icon
          *ngIf="!isHover && !isBusy"
          [name]="'close-52px-active'" [width]="24" [height]="24"
          (click)="onClickClose($event)"
          class="cursor-pointer"></component-sprite-icon>
        <component-sprite-icon
          *ngIf="isHover && !isBusy"
          [name]="'close-52px-hover'" [width]="24" [height]="24"
          (click)="onClickClose($event)"
          class="cursor-pointer"></component-sprite-icon>
        <component-sprite-icon
          *ngIf="isBusy"
          [name]="'close-x-passive'" [width]="24" [height]="24"
        ></component-sprite-icon>
      </div>
      <!-- /Кнопка закрытия модального окна. -->

    </div>
    <!-- /Центральная область модального окна. -->
  </div>
</section>
