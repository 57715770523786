import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ReplaySubject, Subscription } from "rxjs";

import { ModalService } from "../../_services";


/**
 * Компонент обёртка над контентом для модального окна.
 * @param busy - Канал получения состояния "занято", состояние "занято" отключает кнопку закрытия модального окна.
 */
@Component({
  selector: 'ui-modal-wrapper',
  templateUrl: './modal-wrapper.component.html',
  styleUrls: ['./modal-wrapper.component.scss']
})
export class ModalWrapperComponent<T> implements OnInit, OnDestroy {
  private busySubscribe?: Subscription; // Подписка на канал получения статуса "занято".
  private isHover$: boolean = false; // Состояние наведения на кнопку "закрыть".
  private isBusy$: boolean = false; // Состояние "занято".

  public display: boolean;

  @Input('busy') busy$!: ReplaySubject<boolean>; // Канал получения изменений состояния "занято".
  @Output('oClose') readonly click$: EventEmitter<MouseEvent>; // Канал передачи события закрытия окна.

  /**
   * Конструктор.
   * @param modalService - Сервис модальных окон.
   */
  constructor(
    private modalService: ModalService<T>,
  ) {
    this.display = false;
    this.click$ = new EventEmitter<MouseEvent>();
  }

  /** Инициализатор. */
  ngOnInit(): void {
    try {
      this.busySubscribe = this.busy$.subscribe((busy: boolean) => this.isBusy$ = busy);
    } catch (e: unknown) {
      // console.log('Канал получения состояния "занято", в обёртку диалогового окна, не передан.');
    }
    this.display = true;
  }

  /** Деструктор. */
  ngOnDestroy(): void {
    if (this.busySubscribe) {
      this.busySubscribe.unsubscribe();
      this.busySubscribe = undefined;
    }
  }

  /** Возвращает состояние наведения на элементе закрытия модального окна. */
  public get isHover(): boolean {
    return this.isHover$;
  }

  /** Возвращает состояние "занято". */
  public get isBusy(): boolean {
    return this.isBusy$;
  }

  /** Закрытие модального окна. */
  public async close(): Promise<void> {
    this.display = false;
    await this.modalService.close();
  }

  /**
   * События начала наведения мыши на элемент.
   * @param _event - Объект описания события.
   */
  public onMouseEnter(_event: MouseEvent): void {
    this.isHover$ = true;
  }

  /**
   * События окончания наведения мыши на элемент.
   * @param _event - Объект описания события.
   */
  public onMouseLeave(_event: MouseEvent): void {
    this.isHover$ = false;
  }

  /**
   * Функция вызывается при закрытии модального окна.
   * @param e - Описание события клика мышкой.
   */
  public async onClickClose(e: MouseEvent) {
    this.click$.next(e);
    this.close().then();
  }
}
