<div
  (mouseenter)="onMouseEnter('button')"
  (mouseleave)="onMouseLeave('button')"
  [ngClass]="{'blur-sm relative z-[1000]': isBusy}"
  class="select-none">
  <!-- Кнопка, притворяющаяся выпадающим списком. -->
  <button
    type="button"
    (click)="onClick($event)"
    (focusin)="onChangeFocus(true)"
    (focusout)="onChangeFocus(false)"
    [ngClass]="{
      'border-[#5E15E9]': !doHide && isShow,
      'focus:border-[#5E15E9] hover:border-[#5E15E9]': !isLockByFilter && !isBusy,
      'cursor-default': isLockByFilter || isBusy}"
    class="w-full h-[40px] py-[8px] px-[16px]
          text-[16px] leading-6 text-left
          relative block appearance-none
          border rounded-[12px]
          focus:z-2 focus:outline-none focus:border-[#5E15E9]
          hover:border-[#5E15E9]
          placeholder:text-[16px] placeholder:text-[#1C1C1D] placeholder:text-opacity-[16%]">
    <!-- Элементы отображаемые на кнопке. -->
    <div
      class="w-full flex flex-row items-center justify-between">
      <!-- Название выбранного элемента справочника. -->
      <div class="w-full">
        <div class="h-auto w-auto left-0 top-0 flex flex-nowrap relative">
          <div
            *ngIf="!isLockByFilter || isLockCause === ''"
            [ngClass]="{'text-[16px] text-[#1C1C1D] text-opacity-[16%]': isSelectedLabelPlaceholder}"
            class="min-w-fit mr-[8px] text-left whitespace-nowrap truncate">{{
              (isSelectedLabelPlaceholder ? '' : prefix) + selectedLabel
            }}
          </div>
          <div
            *ngIf="isLockByFilter && isLockCause !== ''"
            class="min-w-fit mr-[8px] text-left whitespace-nowrap truncate italic">{{ isLockCause }}
          </div>
        </div>
      </div>
      <!-- /Название выбранного элемента справочника. -->

      <!-- Иконка выпадающего списка. -->
      <div
        *ngIf="!isLockByFilter"
        (click)="onClick($event)"
        class="flex">
        <img
          *ngIf="!isShow && !isFocus"
          ngSrc="assets/images/dropdown-down.svg" height="24" width="24" alt="Раскрыть выпадающий список">
        <img
          *ngIf="!isShow && isFocus"
          ngSrc="assets/images/dropdown-up.svg" height="24" width="24" alt="Закрыть выпадающий список">
        <img
          *ngIf="isShow"
          ngSrc="assets/images/dropdown-up.svg" height="24" width="24" alt="Закрыть выпадающий список">
      </div>
      <!-- /Иконка выпадающего списка. -->
    </div>
    <!-- /Элементы отображаемые на кнопке. -->

    <!-- Выпадающий слой с поиском и скроллингом для размещения элементов выпадающего списка. -->
    <div
      (mouseenter)="onMouseEnter('dropdown')"
      (mouseleave)="onMouseLeave('dropdown')"
      [ngClass]="{'hidden': doHide || !isShow}"
      class="absolute w-[calc(100%-14px)] left-[7px] mt-[7px] origin-top-left bg-white z-50
            border border-[#5E15E9] border-t-[#FFFFFF]
            rounded-b-[12px]
            focus:outline-none">
      <!-- Список элементов для выбора. -->
      <div
        #wrapperWithScroll
        class="w-full max-h-[235px] overflow-scroll fieldset-dropbox-scroll z-[100]">
        <div
          #displayExpectationsData
          *ngFor="let item of items; index as i"
          [ngClass]="{'thisIsSelectedItem': item.id === itemID}"
          (click)="onSelected(item.id)"
          class="w-full px-[7px] py-[12px] appearance-none text-[16px] leading-6
              hover:bg-[#1C1C1D] hover:bg-opacity-[8%] cursor-pointer">
          <div
            class="h-auto w-0 left-0 top-0 flex flex-nowrap relative">
            <div
              [ngClass]="{'text-rnYellow': item.id === itemID}"
              class="ml-[7px] min-w-fit whitespace-nowrap truncate">{{ item.name }}
            </div>
          </div>
        </div>
        <div
          #displayExpectationsEmpty
          *ngIf="items.length === 0" class="ml-[7px] leading-[35px]">Нет данных.
        </div>
      </div>
      <!-- /Список элементов для выбора. -->
    </div>
    <!-- /Выпадающий слой с поиском и скроллингом для размещения элементов выпадающего списка. -->

  </button>
  <!-- /Кнопка, притворяющаяся выпадающим списком. -->

  <!-- Сообщение об ошибке. -->
  <div *ngIf="!isReadonly && (isInvalid || isTouched && selectId<=0)" class="text-[#FF0000] flex flex-col">
    <div
      *ngFor="let e of help"
    >{{ e }}
    </div>
  </div>
  <!-- /Сообщение об ошибке. -->
</div>
