import { Component, ElementRef, EventEmitter, Input, OnDestroy, } from '@angular/core';
import { OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ReplaySubject, Subscription } from "rxjs";

import { DropboxWithFilter, IDropboxItem } from "../../_classes";


@Component({
  selector: 'ui-dropbox-static-data',
  templateUrl: './dropbox-static-data.component.html',
  styleUrls: ['./dropbox-static-data.component.scss']
})
export class DropboxStaticDataComponent extends DropboxWithFilter implements OnInit, OnDestroy {
  private refreshEventSubscribe?: Subscription; // Подписка на канал событий необходимости обновления данных.

  @Input() override placeholder: string;
  @Input() override selectId: number;
  @Input() public refreshEvent!: ReplaySubject<never>;
  @Input() public staticData: IDropboxItem[];
  @Output() override readonly selectIdChange: EventEmitter<number>;

  /** Получение события окончания выполнения цикла ngFor. */
  @ViewChild('wrapperWithScroll', {read: ElementRef}) override wrapperWithScroll$!: ElementRef<HTMLInputElement>;
  @ViewChildren('displayExpectationsData') override displayExpectationsData$!: QueryList<ElementRef>;
  @ViewChildren('displayExpectationsEmpty') override displayExpectationsEmpty$!: QueryList<ElementRef>;

  /**
   * Конструктор.
   */
  constructor() {
    super();
    this.staticData = [];
    this.refreshEventSubscribe = undefined;
    [this.placeholder, this.selectId, this.selectIdChange] = ['-', -1, new EventEmitter<number>()];
  }

  /** Инициализатор. */
  override ngOnInit(): void {
    super.ngOnInit();
    try {
      this.refreshEventSubscribe = this.refreshEvent.subscribe((_: never): void => this.updateData());
    } catch (e: unknown) {
      // console.error('В компонент DropboxUsernameComponent не передан канал событий обновления.');
    }
  }

  /** Деструктор. */
  override ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.refreshEventSubscribe) {
      this.refreshEventSubscribe.unsubscribe();
      this.refreshEventSubscribe = undefined;
    }
  }

  /** Загрузка или обновление данных выпадающего списка. */
  override updateData(): void {
    super.updateData();
    this.isBusy$ = true;
    this.items$ = [];
    // Формирование данных.
    this.staticData.forEach((item: IDropboxItem): void => {
      // Фильтрация.
      if (this.filterByName !== '') {
        if (item.name.indexOf(this.filterByName) != -1) this.items$.push(item);
      } else this.items$.push(item);
    });
    this.items$.sort((a: IDropboxItem, b: IDropboxItem): number => (a.name < b.name ? -1 : 1));
    this.isBusy$ = false;
  }
}
