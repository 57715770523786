import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TelegramUnknownUserComponent } from "./telegram-unknown-user.component";


@NgModule({
  declarations: [
    TelegramUnknownUserComponent,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    TelegramUnknownUserComponent,
  ],
})
export class TelegramUnknownUserModule { }
