import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";

import { SpriteIconModule } from "../../_components";
import { DropboxStaticDataComponent } from './dropbox-static-data.component';


@NgModule({
  declarations: [
    DropboxStaticDataComponent,
  ],
  imports: [
    CommonModule,
    SpriteIconModule,
    FormsModule,
  ],
  exports: [
    DropboxStaticDataComponent,
  ],
})
export class DropboxStaticDataModule { }
