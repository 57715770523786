import { Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ReplaySubject, Subscription } from "rxjs";

import { ModalWrapperComponent } from "../modal-wrapper";


@Component({
  selector: 'ui-delete-regular',
  templateUrl: './delete-regular.component.html',
  styleUrls: ['./delete-regular.component.scss']
})
export class DeleteRegularComponent implements OnInit, OnDestroy {
  private errorActionSubscription?: Subscription = undefined;

  public actionError$: string[] = []; // Ошибки выполнения действия над сущностью.

  @Input('title') public title: string; // Заголовок.
  @Input('message') public message: string[]; // Тело сообщения.
  @Input('responseCh') public responseCh: EventEmitter<boolean>; // Канал возврата результата.
  @Input('errorAction') public errorAction$!: ReplaySubject<string[]>; // Канал получения ошибки выполнения действия над сущностью.

  /** Оборачиваем текущий компонент в компонент modal. */
  @ViewChild('addWrapper') public readonly modal: ModalWrapperComponent<DeleteRegularComponent> | undefined;

  /**
   * Конструктор.
   */
  constructor() {
    this.title = '';
    this.message = [];
    this.responseCh = new EventEmitter<boolean>;
  }

  /** Инициализатор. */
  ngOnInit(): void {
    try {
      this.errorActionSubscription = this.errorAction$.subscribe((v: string[]) => this.actionError$ = v);
    } catch (e: unknown) {
      // console.error('В компонент ClientsDeleteComponent не передан канал получения ошибки выполнения действия над сущностью.');
    }
  }

  /** Деструктор. */
  ngOnDestroy(): void {
    if (this.errorActionSubscription) {
      this.errorActionSubscription.unsubscribe();
      this.errorActionSubscription = undefined;
    }
  }

  /** Закрытие модального окна. */
  public async close(): Promise<void> {
    await this.modal?.close();
  }

  /** Событие нажатия на кнопку "Да". */
  public onYes(_: MouseEvent): void {
    this.responseCh.next(true);
  }

  /** Событие нажатия на кнопку "Нет". */
  public onNot(_: MouseEvent): void {
    this.responseCh.next(false);
  }
}
