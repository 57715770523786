import { AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, Input, ViewChild } from '@angular/core';
import { OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { ReplaySubject, startWith, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

import { IGetListOption, Directory, DirectoryService, DirectoryFiltration } from "../../_services";
import { IFieldsetDropboxDictionary, IFiltrationDictionary } from "./dropbox-dictionary.types";


/**
 * Компонент выпадающего списка со значениями из справочника.
 * Справочник загружается на основе переданного в компонент объекта справочника.
 * В качестве справочника подходит любой справочник в котором есть свойства 'id' и 'name'.
 * @param essence          - Объект справочника.
 * @param selectId         - Уникальный идентификатор выбранного элемента справочника, начальное значение.
 * @param filter           - Фильтрация значений справочника на основе внешних зависимостей.
 * @return selectIdChange  - Канал передачи изменения выбранного значения справочника.
 * @return outViewDone     - Канал передачи события окончания рендеринга тела компонента.
 */
@Component({
  selector: 'ui-dropbox-dictionary',
  templateUrl: './dropbox-dictionary.component.html',
  styleUrls: ['./dropbox-dictionary.component.scss']
})
export class DropboxDictionaryComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  private filterSubscribe?: Subscription; // Подписка на канал получения внешних фильтров значений справочника.
  private onViewDoneSubscribe?: Subscription; // Подписка на канал события окончания отображения компонента.
  private isShow$: boolean = false; // Состояние открыт или закрыт список.
  private isFocus$: boolean = false; // Состояние фокусировки на элементе.
  private isBusy$: boolean; // Состояние занято.
  private items$: IFieldsetDropboxDictionary[]; // Элементы справочника.
  private filter: IFiltrationDictionary[] = []; // Фильтрации запросов.
  private scrollToSelected$: boolean = false;

  public filterByName: string = '';
  public isLockByFilter: boolean = false;
  public isLockCause: string = '';

  /** Вариант для получения события окончания выполнения цикла ngFor */
  @ViewChild('wrapperWithScroll', {read: ElementRef}) public wrapperWithScroll$!: ElementRef<HTMLInputElement>;
  @ViewChildren('displayExpectationsData') displayExpectationsData!: QueryList<ElementRef>;
  @ViewChildren('displayExpectationsEmpty') displayExpectationsEmpty!: QueryList<ElementRef>;

  @Input('essence') public essence$?: Directory;
  @Input('selectId') public selectId: number = -1;
  @Input('filter') public filter$?: ReplaySubject<IFiltrationDictionary[]>;
  @Output('selectIdChange') public selectIdChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() readonly outViewDone: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Конструктор.
   * @param directoryService - Сервис справочников.
   */
  constructor(
    private directoryService: DirectoryService,
  ) {
    this.items$ = [];
    this.isBusy$ = true;
    this.onViewDoneSubscribe = this.outViewDone.subscribe((): boolean => this.isBusy$ = false)
  }

  /** Инициализатор. */
  ngOnInit(): void {
    if (this.essence$ === undefined) {
      console.error('В компонент DropboxDictionaryComponent не передана сущность essence.');
      return
    }
    if (this.filter$ !== undefined) {
      this.filterSubscribe = this.filter$.subscribe((value: IFiltrationDictionary[]): void => {
        this.filter = [];
        value.forEach((v: IFiltrationDictionary) => this.filter.push(v));
        this.isLockByFilter = false;
        this.isLockCause = '';
        this.updateData();
      });
    }
    // Загрузка справочника.
    this.updateData();
  }

  /** Деструктор. */
  ngOnDestroy(): void {
    if (this.filterSubscribe) {
      this.filterSubscribe.unsubscribe();
      this.filterSubscribe = undefined;
    }
    if (this.onViewDoneSubscribe) {
      this.onViewDoneSubscribe.unsubscribe();
      this.onViewDoneSubscribe = undefined;
    }
  }

  /** Событие завершения отображения элементов HTML страницы. */
  ngAfterViewInit(): void {
    this.displayExpectationsData.changes
      .pipe(
        startWith(null),
        debounceTime(0),
      )
      .subscribe(() => this.ngForIsDone());
    this.displayExpectationsEmpty.changes
      .pipe(
        startWith(null),
        debounceTime(0),
      )
      .subscribe(() => this.ngForIsDone());
  }

  /** После проверки вью. Можно менять скроллинг. */
  ngAfterViewChecked(): void {
    if (this.isShow$ && this.scrollToSelected$) {
      this.scrollToSelected$ = false;
      this.scrollTo();
    }
  }

  /** Прокрутка выпадающего списка до выбранного элемента. */
  private scrollTo(): void {
    const className: string = 'thisIsSelectedItem';
    let nodes: NodeListOf<ChildNode>;
    let nodeHeight: number = 0;
    let top: number = 0;

    nodes = this.wrapperWithScroll$.nativeElement.childNodes;
    nodes.forEach((node: ChildNode): void => {
      let div: HTMLDivElement;
      let found: boolean = false;
      if (node.nodeName === 'DIV') {
        div = node as HTMLDivElement;
        div.classList.forEach((value: string, _key: number, _parent: DOMTokenList): void => {
          if (value === className) found = true;
        })
        if (found) {
          nodeHeight = div.offsetHeight;
          top = div.offsetTop - (nodeHeight * 2);
          if (top < 0) top = 1;
        }
      }
    });
    if (top > 0) this.wrapperWithScroll$.nativeElement.scrollTo(0, top);
  }

  /** Событие завершения цикла печати строк выпадающего списка. */
  private ngForIsDone(): void {
    this.outViewDone.emit(void {});
  }

  /** Загрузка или обновление данных справочника с сервера. */
  private updateData(): void {
    const max: number = -1;
    let options: IGetListOption | undefined;

    if (this.essence$ === undefined) return;
    if (this.filterByName !== '' || this.filter.length > 0)
      options = {filter: [], by: [], limit: {offset: 0, limit: max}};
    switch (this.essence$.type) {
      // case "yearrange":
      //   if (this.filterByName !== '') {
      //     options?.filter?.push({name: 'from', type: 'eq', value: this.filterByName});
      //     options?.by?.push({name: 'from', type: 'asc'});
      //     options?.by?.push({name: 'to', type: 'asc'});
      //   }
      //   break;
      default:
        this.essence$.filtration?.forEach((f: DirectoryFiltration): void => {
          if (this.filterByName === '') return;
          switch (f.type) {
            case 'ke':
              options?.filter?.push({name: f.field, type: f.type, value: '*' + this.filterByName + '*'});
              break;
            default:
              options?.filter?.push({name: f.field, type: f.type, value: this.filterByName});
              break;
          }
        })
        if (this.essence$.filtration !== undefined && this.essence$.filtration.length > 1) {
          if (options !== undefined)
            options.tie = 'or';
        }
        break;
    }
    this.filter.forEach((f: IFiltrationDictionary): void => {
      if (f.required && f.value === '') {
        this.isLockByFilter = true;
        if (f.cause) this.isLockCause = f.cause;
        return
      }
      options?.filter?.push({name: f.name, type: f.type, value: f.value});
    });
    if (this.isLockByFilter) return;
    // Сортировка по умолчанию.
    if (options === undefined) {
      options = {by: [], limit: {offset: 0, limit: max}};
      switch (this.essence$.type) {
        // case "yearrange":
        //   options?.by?.push({name: 'from', type: 'asc'});
        //   options?.by?.push({name: 'to', type: 'asc'});
        //   break;
        default:
          options?.by?.push({name: 'name', type: 'asc'});
          break;
      }
    }
    this.isBusy$ = true;
    this.directoryService
      .dropboxInfoById(this.essence$, options)
      .subscribe((data: IFieldsetDropboxDictionary[]): void => {
        this.items$ = [{id: 0, name: 'Не выбрано'}];
        data.forEach((v: IFieldsetDropboxDictionary) => this.items$.push(v));
      })
  }

  /** Состояние открытости списка выбора значений справочника. */
  public get isShow(): boolean {
    return this.isShow$;
  }

  /** Состояние занято. */
  public get isBusy(): boolean {
    return this.isBusy$;
  }

  /** Количество элементов справочника. */
  public get itemCount(): number {
    return this.items$.length;
  }

  /** Идентификатор текущего выбранного элемента справочника. */
  public get itemID(): number {
    return this.selectId;
  }

  /** Выгрузка списка элементов справочника. */
  public get items(): IFieldsetDropboxDictionary[] {
    return this.items$
  }

  /** Значение выбранного элемента формы или '-' */
  public get selectedLabel(): string {
    let ret = '-';

    this.items$.forEach((value: IFieldsetDropboxDictionary) => {
      if (value.id === this.selectId) ret = value.name;
    });

    return ret;
  }

  /** Установка нового значения открытости списка выбора значений справочника. */
  public set isShow(v: boolean) {
    this.isShow$ = v;
  }

  /** Состояние фокусировки на элементе. */
  public get isFocus(): boolean {
    return this.isFocus$
  }

  /** Изменение состояния фокусировки пользователя на элементе. */
  public onChangeFocus(v: boolean): void {
    if (this.isBusy) return;
    this.isFocus$ = v;
  }

  /** Изменение состояния открытости или закрытости списка справочника. */
  public onChangeShow(): void {
    if (this.isBusy) return;
    this.scrollToSelected$ = true;
    if (this.isLockByFilter && !this.isShow$) return;
    this.isShow$ = !this.isShow$;
  }

  /** Изменилось значение в поле фильтра. */
  public onChangeFilter(_: KeyboardEvent): void {
    // Загрузка справочника.
    this.updateData();
  }

  /** Выбор элемента справочника и отправка события об изменении значения. */
  public onSelected(id: number): void {
    this.selectId = id;
    this.selectIdChange.next(this.selectId);
    this.isShow = false;
  }
}
