import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';

import { DropboxAppCityComponent } from "./dropbox-app-city.component";
import { SpriteIconModule } from "../../_components";


@NgModule({
  declarations: [
    DropboxAppCityComponent,
  ],
  imports: [
    CommonModule,
    SpriteIconModule,
    NgOptimizedImage,
  ],
  exports: [
    DropboxAppCityComponent,
  ],
})
export class DropboxAppCityModule { }
