import { EventEmitter, Injectable } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { lastValueFrom, Subscription } from "rxjs";

import {
  TelegramService,
  BottomButton,
  ITelegramAppdataCheckRequest,
  TelegramRestApiService,
  ITelegramAppdataCheckResponse
} from "../_services";
import { appUserKey } from "../miniapp/miniapp.types";
import { IButton, ICondition, IWildCard, TButton } from "./condition-telegram.types";


/**
 * Родительский класс состояния приложения телеграм,
 * использующего "шаги" для достижения результата.
 */
@Injectable({
  providedIn: 'root',
})
export class ConditionTelegram {
  private routeSubscription?: Subscription = undefined; // Подписка на событие изменения роутинга.
  protected conditionSubscription?: Subscription; //////// Подписка на событие изменения состояния.
  protected conditionEvent: EventEmitter<void>; ////////// Канал событий изменения состояния.
  protected condition: ICondition; /////////////////////// Текущее состояние.
  protected clickMainSubscription?: Subscription; //////// Подписка на клик по главной кнопке.
  protected clickSecondSubscription?: Subscription; ////// Подписка на клик по второй кнопке.
  protected clickBackSubscription?: Subscription; //////// Подписка на клик по кнопке "Назад".
  protected clickSeSettingsSubscription?: Subscription; // Подписка на клик по кнопке "Настройки".

  protected isUnassigned: boolean; //////////////////////////////////// Отсутствие идентификации пользователя телеграм.
  protected telegramUser: ITelegramAppdataCheckResponse | undefined; // Идентифицированный пользователь телеграм.

  /** Конструктор. */
  constructor(
    protected telegramService: TelegramRestApiService,
    protected tg: TelegramService,
    protected activatedRoute: ActivatedRoute,
  ) {
    [this.routeSubscription, this.conditionSubscription] = [undefined, undefined];
    this.conditionEvent = new EventEmitter<void>();
    this.condition = {step: 1, pattern: []};
    [this.clickMainSubscription, this.clickSecondSubscription] = [undefined, undefined];
    [this.clickBackSubscription, this.clickSeSettingsSubscription] = [undefined, undefined];
    [this.isUnassigned, this.telegramUser] = [false, undefined];
  }

  /** Инициализатор. */
  protected onInit(): void {
    this.auth();
    // Подписка на изменения состояния приложения.
    this.conditionSubscription = this.conditionEvent.subscribe((_: void): void => this.onCondition());
    // Подписка на клики по всем кнопкам.
    this.clickMainSubscription = this.tg.MainButtonOnClick.subscribe(async (_: never): Promise<void> =>
      await this.onClick('main'));
    this.clickSecondSubscription = this.tg.SecondaryButtonOnClick.subscribe(async (_: never): Promise<void> =>
      await this.onClick('second'));
    this.clickBackSubscription = this.tg.BackButtonOnClick.subscribe(async (_: never): Promise<void> =>
      await this.onClick('back'));
    this.clickSeSettingsSubscription = this.tg.SettingsButtonOnClick.subscribe(async (_: never): Promise<void> =>
      await this.onClick('settings'));
  }

  /** Деструктор. */
  protected onDestroy(): void {
    if (this.routeSubscription !== undefined) {
      this.routeSubscription.unsubscribe();
      this.routeSubscription = undefined;
    }
    if (this.conditionSubscription !== undefined) {
      this.conditionSubscription.unsubscribe();
      this.conditionSubscription = undefined;
    }
    if (this.clickMainSubscription !== undefined) {
      this.clickMainSubscription.unsubscribe();
      this.clickMainSubscription = undefined;
    }
    if (this.clickSecondSubscription !== undefined) {
      this.clickSecondSubscription.unsubscribe();
      this.clickSecondSubscription = undefined;
    }
    if (this.clickBackSubscription !== undefined) {
      this.clickBackSubscription.unsubscribe();
      this.clickBackSubscription = undefined;
    }
    if (this.clickSeSettingsSubscription !== undefined) {
      this.clickSeSettingsSubscription.unsubscribe();
      this.clickSeSettingsSubscription = undefined;
    }
  }

  /**
   * Аутентификация приложения.
   * @protected
   */
  private auth(): void {
    // Ожидание готовности библиотеки телеграм.
    this.tg.Api.ready();
    // Подписка на изменение роутинга.
    this.routeSubscription = this.activatedRoute.queryParams.subscribe((p: Params): void => this.authOnRouteReady(p));
  }

  /**
   * Событие готовности роутинга.
   * @param params - параметры роутинга.
   * @private
   */
  private authOnRouteReady(params: Params): void {
    const appToken: string = params[appUserKey];
    const req: ITelegramAppdataCheckRequest = {appToken: appToken, initData: this.tg.Api.initData};
    lastValueFrom(this.telegramService.appdataCheck(req))
      .then((telegramUser: ITelegramAppdataCheckResponse): void => {
        this.telegramUser = telegramUser;
        this.onReady();
      })
      .catch((e: unknown): void => {
        this.isUnassigned = true;
        // console.error(`Сервер вернул ошибку: ${e}`);
      })
  }

  /**
   * Функция вызывается после аутентификации пользователя на бек-энд сервере и полной готовности
   * API библиотеки работы с телеграм клиентом.
   * @protected
   */
  protected onReady(): void {
    console.log('onReady()')
  }

  /** Установка настроек для кнопки внизу. */
  private buttonBottom(b: BottomButton, w: IButton): void {
    if (w.color_text && w.color_text !== '' && w.color_bg && w.color_bg !== '') {
      b.setParams({
        text: w.text,
        is_visible: !(w.isHide != undefined ? w.isHide : false),
        is_active: w.isActive,
        color: w.color_bg,
        text_color: w.color_text
      });
      return;
    }
    b.setText(w.text);
    b.show();
    w.isActive ? b.enable() : b.disable();
    if (w.isHide != undefined) w.isHide ? b.hide() : b.show();
    else b.show();
  }

  /** Основные настройки интерфейса приложения. */
  private mainSettings(sn: number, wc: IWildCard): void {
    if (wc.isCloseConfirm !== undefined) {
      wc.isCloseConfirm ? this.tg.Api.enableClosingConfirmation() : this.tg.Api.disableClosingConfirmation();
    }
    if (wc.isExpand !== undefined) {
      wc.isExpand ? this.tg.Api.expand() : (): void => {
      };
    }
    // Кнопка "Главная".
    if (wc.ButtonMain !== undefined) {
      this.buttonBottom(this.tg.Api.MainButton, wc.ButtonMain!);
    } else this.tg.Api.MainButton.hide();
    // Кнопка "Вторая".
    if (wc.ButtonSecondary !== undefined) {
      this.buttonBottom(this.tg.Api.SecondaryButton, wc.ButtonSecondary!);
    } else this.tg.Api.SecondaryButton.hide();
    // Кнопка "Назад".
    if (wc.ButtonBack !== undefined) {
      wc.ButtonBack.isShow ? this.tg.Api.BackButton.show() : this.tg.Api.BackButton.hide();
    } else this.tg.Api.BackButton.hide();
    // Кнопка "Настройки".
    if (wc.ButtonSettings !== undefined) {
      wc.ButtonSettings.isShow ? this.tg.Api.SettingsButton.show() : this.tg.Api.SettingsButton.hide();
    } else this.tg.Api.SettingsButton.hide();
    // Вызов функции смены шага.
    if (wc.onStep !== undefined) {
      try {
        wc.onStep(sn);
      } catch (e: unknown) {
        console.error(`Вызов функции onStepFn() на шаге ${sn} прерван ошибкой: ${e}`);
      }
    }
  }

  /** Обработка событий клика по всем кнопкам. */
  private async onClick(bt: TButton): Promise<void> {
    const sn: number = this.condition.step;
    let wc: IWildCard;
    let fn: ((tb: TButton) => void) | undefined;

    wc = this.condition.pattern[sn];
    if (!wc) {
      return
    }
    switch (bt) {
      case "main":
        fn = wc.ButtonMain?.click;
        break;
      case "second":
        fn = wc.ButtonSecondary?.click;
        break;
      case "back":
        fn = wc.ButtonBack?.click;
        break;
      case "settings":
        fn = wc.ButtonSettings?.click;
        break;
      default:
        console.log(`Не описанный тип кнопки интерфейса '${sn}'.`)
        return;
    }
    if (fn === undefined) {
      return;
    }
    try {
      fn(bt);
    } catch (e: unknown) {
      console.error(`Вызов функции click() кнопки ${bt} прерван ошибкой: ${e}`);
    }
  }

  /** Обработка события изменения состояния приложения. */
  protected onCondition(): void {
    const sn: number = this.condition.step;
    let wc: IWildCard;

    // Отключение всех кнопок и блокировок.
    if (sn === 0) {
      this.tg.Api.MainButton.hide();
      this.tg.Api.SecondaryButton.hide();
      this.tg.Api.BackButton.hide();
      this.tg.Api.SettingsButton.hide();
      this.tg.Api.disableClosingConfirmation();
      return;
    }
    wc = this.condition.pattern[sn];
    if (!wc) {
      console.log(`Шаблон настроек шага ${sn} отсутствует.`)
      return
    }
    // Основные настройки интерфейса приложения.
    this.mainSettings(sn, wc);
  }
}
