import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SpriteIconModule } from "../../_components";
import { ModalWrapperComponent } from './modal-wrapper.component';


@NgModule({
  declarations: [
    ModalWrapperComponent,
  ],
  imports: [
    CommonModule,
    SpriteIconModule,
  ],
  exports: [
    ModalWrapperComponent,
  ],
})
export class ModalWrapperModule { }
