<fieldset
  [ngClass]="{'blur-sm relative z-[1000]': isBusy}"
  class="select-none">

  <!-- Кнопка, притворяющаяся элементом выбора значений из списка значений. -->
  <button
    aria-haspopup="true"
    type="button"
    (click)="onChangeShow()"
    (focusin)="onChangeFocus(true)"
    (focusout)="onChangeFocus(false)"
    [ngClass]="{
      'border-bbBlueButtonBorder': isShow,
      'border-bbGrayTableBorder': !isShow,
      'focus:border-bbBlueButtonBorder hover:border-bbBlueButtonBorder':!isLockByFilter && !isBusy,
      'cursor-default':isLockByFilter || isBusy}"
    class="w-full flex flex-row relative py-[10px] px-[14px] text-[12px] dropdown:block
      border rounded-[4px] focus:outline-none focus:shadow-outline">
    <div class="w-full flex flex-row items-center justify-between">

      <!-- Название выбранного элемента справочника. -->
      <div class="w-full">
        <div class="h-auto w-auto left-0 top-0 flex flex-nowrap relative">
          <div
            *ngIf="!isLockByFilter || isLockCause === ''"
            class="min-w-fit mr-[8px] text-left whitespace-nowrap truncate">{{ selectedLabel }}
          </div>
          <div
            *ngIf="isLockByFilter && isLockCause !== ''"
            class="min-w-fit mr-[8px] text-left whitespace-nowrap truncate italic">{{ isLockCause }}
          </div>
        </div>
      </div>
      <!-- /Название выбранного элемента справочника. -->

      <!-- Блок иконки, меняющейся в зависимости от состояния элемента. -->
      <div *ngIf="!isLockByFilter" (click)="onChangeShow()">
        <component-sprite-icon
          *ngIf="!isShow && !isFocus"
          [name]="'dropdown-down-52px-passive'"
          [width]="10"
          [height]="7"></component-sprite-icon>
        <component-sprite-icon
          *ngIf="!isShow && isFocus"
          [name]="'dropdown-down-52px-active'"
          [width]="10"
          [height]="7"></component-sprite-icon>
        <component-sprite-icon
          *ngIf="isShow"
          [name]="'dropdown-up-52px-active'"
          [width]="10"
          [height]="7"></component-sprite-icon>
      </div>
      <!-- /Блок иконки, меняющейся в зависимости от состояния элемента. -->

    </div>
  </button>
  <!-- /Кнопка, притворяющаяся элементом выбора значений из списка значений. -->

  <!-- Выпадающий слой с поиском и скроллингом для размещения элементов справочника. -->
  <div
    (mouseleave)="isShow=false"
    [ngClass]="{'hidden':!isShow}"
    class="w-full z-2 max-h-[300px] py-[10px] px-[14px] flex flex-col justify-between text-left items-center
      relative left-0 -top-2 shadow-lg overflow-hidden
      border rounded-[4px] border-bbBlueButtonBorder bg-bbWhite border-t-bbWhite">

    <!-- Поле ввода значения фильтрации справочника. -->
    <div class="w-full min-h-[35px] mb-[16px]">
      <input
        type="text"
        name="filter"
        [placeholder]="'Поиск'"
        [(ngModel)]="filterByName"
        (keyup)="onChangeFilter($event)"
        class="relative block w-full p-[5px] pr-[24px] text-[12px] appearance-none rounded-none focus:z-2 focus:outline-none
          border border-bbBlueButtonBorder"/>
      <span class="float-right mr-[7px] -mt-[22px] relative z-2 bg-white">
        <component-sprite-icon [name]="'search-52px-active'" [width]="20" [height]="20"></component-sprite-icon>
      </span>
    </div>
    <!-- /Поле ввода значения фильтрации справочника. -->

    <!-- Список элементов справочника для выбора. -->
    <div
      #wrapperWithScroll
      class="w-full max-h-[280px] overflow-scroll fieldset-dropbox-scroll">
      <div
        #displayExpectationsData
        *ngFor="let item of items; index as i"
        (click)="onSelected(item.id)"
        [ngClass]="{'thisIsSelectedItem': itemID === item.id}"
        class="leading-[24px] mr-[3px] overflow-hidden hover:font-bold hover:bg-bbGrayTableSelected">
        <div
          class="h-auto w-0 left-0 top-0 flex flex-nowrap relative">
          <div
            [ngClass]="{'text-rnYellow': itemID === item.id}"
            class="min-w-fit whitespace-nowrap truncate">{{ item.name }}
          </div>
        </div>
      </div>
      <div
        #displayExpectationsEmpty
        *ngIf="itemCount === 0" class="leading-[35px]">Нет данных.
      </div>
    </div>
    <!-- /Список элементов справочника для выбора. -->

  </div>
  <!-- Выпадающий слой с поиском и скроллингом для размещения элементов справочника. -->

</fieldset>
