import { Component } from '@angular/core';


@Component({
  selector: 'app-telegram-unknown-user',
  templateUrl: './telegram-unknown-user.component.html',
  styleUrl: './telegram-unknown-user.component.scss'
})
export class TelegramUnknownUserComponent {
}
